<template>
  <div class="app-container calendar-list-container">
    <basic-container>
      <el-tabs @tab-click="switchTab" v-model="switchStatus" tabPosition="left">
        <!--        <el-tab-pane label='基本信息' name='userManager'>
                  <el-form :model="ruleForm"
                           ref="ruleForm"
                           label-width="100px"
                           v-if="switchStatus==='userManager'">
                    <el-form-item label="用户名"
                                  prop="username">
                      <el-input type="text"
                                v-model="ruleForm.username"
                                disabled></el-input>
                    </el-form-item>
                    <el-form-item label="机构"
                                  prop="organId">
                      <avue-input-tree v-model="ruleForm.organId"
                                  type="tree"
                                  placeholder="请选择所属机构"
                                  :dic="treeOrganData"
                                  :props="organProps"
                                  disabled></avue-input-tree>
                    </el-form-item>
                    <el-form-item label="手机号" prop="phone">
                      <el-row>
                        <el-col :span="6" style="padding-right: 20px">
                          <el-input v-model="phone" placeholder="请绑定手机号" disabled></el-input>
                        </el-col>
                        <el-col :span="10" v-if="phone">
                          <el-button @click="showBindPhone('3')">解绑</el-button>
                        </el-col>
                        <el-col :span="10" v-if="!phone">
                          <el-button @click="showBindPhone('2')">绑定手机</el-button>
                        </el-col>
                      </el-row>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email">
                      <el-input v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="">
                      <avue-form :option="optionAvatar" v-model="ruleForm" :upload-after="handleAvatarSuccess"></avue-form>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary"
                                 @click="submitForm('ruleForm')">提交
                      </el-button>
                    </el-form-item>
                  </el-form>
                </el-tab-pane>-->
        <el-tab-pane label='Password Manager' name='passwordManager' style="width: 500px">
          <el-form :model="ruleForm"
                   :rules="rules"
                   ref="ruleForm"
                   label-width="200px"
                   v-if="switchStatus==='passwordManager'">
            <el-form-item label="password" prop="password">
              <el-input type="Password" v-model="ruleForm.password"></el-input>
            </el-form-item>
            <el-form-item label="New Password"
                          prop="newpassword1">
              <el-input type="password"
                        v-model="ruleForm.newpassword1"></el-input>
            </el-form-item>
            <el-form-item label="Confirm Password"
                          prop="newpassword2">
              <el-input type="password"
                        v-model="ruleForm.newpassword2"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary"
                         @click="submitForm('ruleForm')">提交
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <!--        <el-tab-pane label='操作日志' name='logManager'>
                  <avue-crud ref="crud"
                             :page="page"
                             :data="tableData"
                             :table-loading="tableLoading"
                             :option="tableOption"
                             :permission="permissionList"
                             @on-load="getPage"
                             @search-change="searchChange"
                             @refresh-change="refreshChange"
                             @sort-change="sortChange"
                             @row-del="handleDel">
                  </avue-crud>
                </el-tab-pane>
                <el-tab-pane label='登录日志' name='logloginManager'>
                  <avue-crud ref="crud"
                             :page="page2"
                             :data="tableData2"
                             :table-loading="tableLoading2"
                             :option="tableOption2"
                             :permission="permissionList2"
                             @on-load="getPage2"
                             @search-change="searchChange2"
                             @refresh-change="refreshChange2"
                             @sort-change="sortChange2"
                             @row-del="handleDel2">
                  </avue-crud>
                </el-tab-pane>-->
      </el-tabs>
      <el-dialog
          :title="doType == '2' ? '绑定手机号' : '解绑手机'"
          :visible.sync="dialogBindPhone"
          :close-on-click-modal="false"
          width="30%">
        <!--        <codePhone :type="doType" :phone="phone" @handleDone="handleBindPhone"/>-->
      </el-dialog>
    </basic-container>
  </div>
</template>


<script>
import {mapGetters, mapState} from 'vuex'
import store from "@/store"
import {fetchTree} from "@/api/upms/organ"
import {delObj, getPage} from '@/api/upms/log'
import {tableOption} from '@/const/crud/upms/log'
import {tableOption as tableOption2} from '@/const/crud/upms/loglogin'
import {delObj as delObj2, getPage as getPage2} from '@/api/upms/loglogin'
import {bindPhone, editObj} from "@/api/upms/user"
/*import codePhone from '@/components/code-phone/main.vue'*/

export default {
  /*components: {
    codePhone
  },*/
  data() {
    var validatePass = (rule, value, callback) => {
      if (this.ruleForm.password !== '') {
        if (value !== this.ruleForm.newpassword1) {
          callback(new Error('Inconsistent passwords entered twice!'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      tableData: [],
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        ascs: [],
        descs: 'create_time'
      },
      tableLoading: false,
      tableOption: tableOption,
      tableData2: [],
      page2: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        ascs: [],
        descs: 'create_time'
      },
      tableLoading2: false,
      tableOption2: tableOption2,
      treeOrganData: [],
      organProps: {
        label: 'name',
        value: 'id',
      },
      optionAvatar: {
        emptyBtn: false,
        submitBtn: false,
        labelWidth: 120,
        column: [
          {
            prop: 'avatar',
            type: 'upload',
            listType: 'picture-img',
            action: '/upms/file/upload?fileType=image&dir=user/',
            propsHttp: {
              url: 'link'
            },
            loadText: '附件上传中，请稍等',
            span: 24,
            tip: '只能上传jpg/png文件，且不超过500kb',
          }
        ]
      },
      switchStatus: '',
      show: false,
      headers: {
        Authorization: 'Bearer ' + store.getters.access_token
      },
      phone: null,
      ruleForm: {
        username: '',
        password: '',
        newpassword1: '',
        newpassword2: '',
        avatar: '',
        email: '',
      },
      rules: {
        password: [{
          required: true,
          min: 6,
          message: 'The original password cannot be empty and should be at least 6 digits.',
          trigger: 'change'
        }],
        newpassword1: [{required: true, min: 6, message: 'Not less than 6', trigger: 'change'}],
        newpassword2: [{required: true, validator: validatePass, trigger: 'blur'}]
      },
      dialogBindPhone: false,
      doType: null
    }
  },
  created() {
    this.ruleForm.id = this.userInfo.id
    this.ruleForm.organId = this.userInfo.organId
    this.ruleForm.username = this.userInfo.username
    this.phone = this.userInfo.phone
    this.ruleForm.email = this.userInfo.email
    this.ruleForm.avatar = this.userInfo.avatar != null ? this.userInfo.avatar : ''
    this.switchStatus = 'passwordManager'
    this.handleOrgan()
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    }),
    ...mapGetters(['permissions']),
    permissionList() {
      return {
        delBtn: this.permissions['sys:log:del'] ? true : false
      };
    },
    permissionList2() {
      return {
        addBtn: this.permissions['sys:loglogin:add'] ? true : false,
        delBtn: this.permissions['sys:loglogin:del'] ? true : false,
        editBtn: this.permissions['sys:loglogin:edit'] ? true : false,
        viewBtn: this.permissions['sys:loglogin:get'] ? true : false
      };
    }
  },
  methods: {
    handleBindPhone(loginForm, type) {
      bindPhone(Object.assign({
        id: this.ruleForm.id,
        doType: type,
      }, loginForm)).then(response => {
        if (response.data.code == 0) {
          let message = ''
          if (type == '2') {
            this.phone = loginForm.phone
            message = '绑定成功'
          } else {
            this.phone = null
            message = '解绑成功'
          }
          this.dialogBindPhone = false
          this.$store.dispatch("GetUserInfo")
          this.$notify({
            title: '成功',
            message: message,
            type: 'success',
            duration: 2000
          })
        }
      })
    },
    showBindPhone(doType) {
      this.doType = doType
      this.dialogBindPhone = true
    },
    handleOrgan() {
      fetchTree().then(response => {
        this.treeOrganData = response.data.data;
      });
    },
    switchTab(tab) {
      this.switchStatus = tab.name
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          editObj(this.ruleForm).then(response => {
            if (response.data.code === 200) {
              this.$notify({
                title: 'success',
                message: 'Modified successfully',
                type: 'success',
                duration: 2000
              })
              // 修改密码之后强制重新登录
              if (this.switchStatus === 'passwordManager') {
                this.$store.dispatch('LogOut').then(() => {
                  location.reload() // 为了重新实例化vue-router对象 避免bug
                })
              }
            } else {
              this.$notify({
                title: 'fail',
                message: response.data.msg,
                type: 'error',
                duration: 2000
              })
            }
          }).catch(() => {
            this.$notify({
              title: 'fail ',
              message: 'Modified Failed',
              type: 'error',
              duration: 2000
            })
          })
        } else {
          return false
        }
      })
    },
    handleAvatarSuccess(res, done) {
      this.ruleForm.avatar = res.url;
      done()
    },
    sortChange(val) {
      let prop = val.prop ? val.prop.replace(/([A-Z])/g, "_$1").toLowerCase() : '';
      if (val.order == 'ascending') {
        this.page.descs = []
        this.page.ascs = prop
      } else if (val.order == 'descending') {
        this.page.ascs = []
        this.page.descs = prop
      } else {
        this.page.ascs = []
        this.page.descs = []
      }
      this.getPage(this.page)
    },
    getPage(page, params) {
      this.tableLoading = true
      getPage(Object.assign({
        current: page.currentPage,
        size: page.pageSize,
        descs: this.page.descs,
        ascs: this.page.ascs,
        createId: this.userInfo.id
      }, params)).then(response => {
        this.tableData = response.data.data.records
        this.page.total = response.data.data.total
        this.page.currentPage = page.currentPage
        this.page.pageSize = page.pageSize
        this.tableLoading = false
      })
    },
    handleDel: function (row) {
      var _this = this
      this.$confirm('是否确认删除?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        return delObj(row.id)
      }).then(() => {
        this.getPage(this.page)
        _this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
      }).catch(function () {
      })
    },
    /**
     * 搜索回调
     */
    searchChange(form, done) {
      this.getPage(this.page, form)
      done()
    },
    /**
     * 刷新回调
     */
    refreshChange() {
      this.getPage(this.page)
    },

    searchChange2(params, done) {
      params = this.filterForm(params)
      this.page2.currentPage = 1
      this.getPage2(this.page2, params)
      done()
    },
    sortChange2(val) {
      let prop = val.prop ? val.prop.replace(/([A-Z])/g, "_$1").toLowerCase() : ''
      if (val.order == 'ascending') {
        this.page2.descs = []
        this.page2.ascs = prop
      } else if (val.order == 'descending') {
        this.page2.ascs = []
        this.page2.descs = prop
      } else {
        this.page2.ascs = []
        this.page2.descs = []
      }
      this.getPage2(this.page2)
    },
    getPage2(page, params) {
      this.tableLoading2 = true
      getPage2(Object.assign({
        current: page.currentPage,
        size: page.pageSize,
        descs: this.page2.descs,
        ascs: this.page2.ascs,
        createId: this.userInfo.id
      }, params)).then(response => {
        this.tableData2 = response.data.data.records
        this.page2.total = response.data.data.total
        this.page2.currentPage = page.currentPage
        this.page2.pageSize = page.pageSize
        this.tableLoading2 = false
      }).catch(() => {
        this.tableLoading2 = false
      })
    },
    /**
     * @title 数据删除
     * @param row 为当前的数据
     * @param index 为当前删除数据的行数
     *
     **/
    handleDel2: function (row) {
      let _this = this
      this.$confirm('是否确认删除此数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        return delObj2(row.id)
      }).then(() => {
        _this.$message({
          showClose: true,
          message: '删除成功',
          type: 'success'
        })
        this.getPage2(this.page2)
      }).catch(function () {
      })
    },
    /**
     * 刷新回调
     */
    refreshChange2() {
      this.getPage2(this.page2)
    }
  }
}
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
