<template>
  <div class="body-box">
    <div class="body-box2">
      <div class="login-box  ">
        <div class="clearfix">
          <!-- Form Panel    -->
          <div class="login-box-l">
            <div class="top-pic"></div>
            <div>
              <div class="l-tit">{{ $t('login.title') }}
                <img src="/img/arrow.png">
              </div>
              <div class="l-con">
                <div style="font: bold;font-size: 30px;margin-bottom: 20px">{{ $t('title') }}
                  <top-lang></top-lang>
                </div>
                <form method="get" class="form-validate" id="loginFrom" @submit.prevent="submitForm">
                  <div id="errorMessage" style="display: none"></div>
                  <span id="error-message" class="error-message" style="color: red;">{{ fromData.errorMsg }}</span>
                  <div class="form-group">
                    <input id="login-username" type="text" name="username"
                           v-model="fromData.username" required :placeholder="$t('login.username')"
                           class="input-material">
                  </div>
                  <div class="form-group">
                    <input id="login-password" type="password" name="password"
                           v-model="fromData.password" required :placeholder="$t('login.password')"
                           class="input-material">
                  </div>
                  <div style="display: flex">
                    <el-button type="primary" @click="downloadAuthorization()" style="margin-right: 30px">
                      {{ $t('login.downloadLicenseKey') }}
                    </el-button>
                    <el-upload :auto-upload="true"
                               :show-file-list="false"
                               :action="importUrl"
                               :on-success="uploadSuccess"
                               :on-error="uploadError"
                               name="licFile"
                               accept=".lic">
                      <el-button type="success">{{ $t('login.installLicense') }}</el-button>
                    </el-upload>
                  </div>
                  <button id="login" class="btn btn-primary">{{ $t('login.submit') }}</button>
                </form>
                <br/>
              </div>
            </div>
          </div>
          <div class="login-box-r"><img src="/img/bg/logo-boxbg01.png"></div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import topLang from "@/page/index/top/top-lang";

export default {
  name: "login",
  components: {topLang},
  data() {
    return {
      fromData: {
        username: "",
        password: "",
        errorMsg: ""
      },
      downloadUrl: '/auth/license/all/server',
      importUrl: '/auth/license/all/install',
    }
  },
  methods: {
    submitForm() {
      this.$store.dispatch("LoginByUsername", this.fromData).then(() => {
        this.$store.commit("SET_TOP_MENU_INDEX", 0)
        this.$router.go(0)
        //window.location.href = "/"
      }).catch((error) => {
        if (error.toString().indexOf('证书') !== -1) {
          this.fromData.errorMsg = this.$t('login.licenseErrorMsg')
        } else {
          this.fromData.errorMsg = error
        }
      })
    },
    downloadAuthorization() {
      //window.open(this.downloadUrl)
      this.downFile(this.downloadUrl, "authorize.key");
    },
    uploadSuccess(res, file) {
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: this.$t('login.installSuccess'),
          type: 'success'
        })
      } else {
        this.$message({
          showClose: true,
          message: this.$t('login.installFailed') + res.msg,
          type: 'error'
        })
      }
    },
    uploadError(error, file) {
      this.$message({
        showClose: true,
        message: error,
        type: 'error'
      })
    },
  }
}
</script>

<style scoped>
@import url("https://lib.sinaapp.com/js/bootstrap/4.2.1/css/bootstrap.min.css");
@import "../../styles/login.css";

.form-group .input-material {
  border: solid 1px #dcdfe6;
}

</style>