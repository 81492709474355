import request from '@/router/axios'

export function getPage(query) {
    return request({
        url: '/upms/sys/log/page',
        method: 'get',
        params: query
    })
}

export function delObj(id) {
    return request({
        url: '/upms/sys/log/' + id,
        method: 'delete'
    })
}
